import React, { useState } from "react";
import logo from "./logo.svg";
import axios from "axios";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function App() {
  const [mediaUrl, setMediaUrl] = useState(
    "https://www.instagram.com/p/CIN9ZKll5FY/"
  );
  const [mediaDetailResponse, setMediaDetailResponse] = useState(null);
  const [shortCode, setShortCode] = useState("");
  const [comments, setComments] = useState([]);
  const [lastCommentResponse, setLastCommentResponse] = useState({});

  const getMediaDetails = async () => {
    const mediaShortcode = mediaUrl
      .split("https://www.instagram.com/p/")[1]
      .replace("/", "");

    setShortCode(mediaShortcode);

    const response = await axios.get(
      "https://www.instagram.com/p/" + mediaShortcode + "/?__a=1"
    );
    const mediaDetails = response.data.graphql.shortcode_media;

    setMediaDetailResponse(mediaDetails);
  };

  const getComments = async (lastResponse = {}) => {
    let after = false;

    if (lastResponse.has_next_page) {
      after = lastResponse.end_cursor;
    } else {
      after = null;
    }

    let variables = { shortcode: shortCode, first: 48 };
    if (after) variables = { shortcode: shortCode, first: 48, after };

    const url =
      "https://www.instagram.com/graphql/query/?query_hash=bc3296d1ce80a24b1b6e40b1e72903f5&variables=" +
      JSON.stringify(variables);

    const response = await axios.get(url);
    const data =
      response.data.data.shortcode_media.edge_media_to_parent_comment;
    const pageInfo = data.page_info;
    const has_next_page = pageInfo.has_next_page;
    const end_cursor = pageInfo.end_cursor;

     setLastCommentResponse({ has_next_page, end_cursor });

    let b = comments;
    data.edges.forEach((x) => {
      b.push(x);
    });

    setComments(b);

    await sleep(5000);
    await getComments({ has_next_page, end_cursor });
  };

  return (
    <div className="App" style={{ margin: 15 }}>
      <h1>Instagram Link</h1>

      <input
        style={{ width: 300 }}
        type="text"
        onChange={(e) => setMediaUrl(e.target.value)}
        defaultValue="https://www.instagram.com/p/CIN9ZKll5FY/"
      />

      <input
        onClick={() => {
          getMediaDetails();
        }}
        type="button"
        value="suche"
      ></input>

      {mediaDetailResponse && <MediaDetails {...mediaDetailResponse} />}
      {mediaDetailResponse && (
        <div>
          <input
            onClick={() => {
              getComments();
            }}
            type="button"
            value="suche"
          ></input>

          <br />
        </div>
      )}

      {renderComments(comments)}
    </div>
  );
}

const MediaDetails = (props) => {
  const display_url = props.display_url;
  const count = props.edge_media_preview_comment.count;

  return (
    <>
      <div>Anzahl comments: {count}</div>
      <img style={{ height: 400 }} src={display_url} alt="" />
    </>
  );
};

const renderComments = (comments) => {
  console.log("cpomments", comments);
  return (
    <ul>
      {comments.map((x) => {
        return (
          <li key={x.node.id}>
            {x.node.text} {x.node.owner.username}
          </li>
        );
      })}
    </ul>
  );
};

export default App;
